.layout-topbar-dark {
  $topbarBgColor: #434b54;
  $topbarBorderColor: #4e565f;

  $topbarItemTextColor: #cacfdb;
  $topbarItemSecondaryTextColor: rgba($topbarItemTextColor, 0.7);
  $topbarItemTextHoverBgColor: #4e565f;
  $topbarItemBgColor: rgba(#cacfdb, 0.2);
  $topbarMenuButtonBgColor: rgba(#cacfdb, 0.1);

  $topbarItemSubmenuItemHoverBgColor: #434b54;
  $topbarSubmenuHeaderBgColor: #434b54;

  $topbarItemSubmenuBgColor: #434b54;
  $topbarItemSubmenuTextColor: #f8fafc;
  $topbarItemSubmenuSecondaryTextColor: rgba(#f8fafc, 0.7);
  $topbarItemSubmenuBoxShadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);

  @import '../topbar_theme';
}
