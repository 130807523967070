//general
$fontSize: 14px !default;
$fontFamily: 'Open Sans' !default;
$mobileBreakpoint: 991px !default;
$borderRadius: 5px !default;
$animationDuration: 0.2s !default;
$animationTimingFunction: cubic-bezier(0.05, 0.74, 0.2, 0.99) !default;
$letterSpacing: normal !default;
$transitionDuration: 0.2s !default;
$tabletBreakpoint: 991px !default;
$phoneBreakpoint: 576px !default;
