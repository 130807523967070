.layout-topbar-dim {
  $topbarBgColor: #3c4962;
  $topbarBorderColor: #495771;

  $topbarItemTextColor: #cacfdb;
  $topbarItemSecondaryTextColor: rgba($topbarItemTextColor, 0.7);
  $topbarItemTextHoverBgColor: #495771;
  $topbarItemBgColor: rgba(#97a2b3, 0.2);
  $topbarMenuButtonBgColor: rgba(#97a2b3, 0.1);

  $topbarItemSubmenuItemHoverBgColor: #3c4962;
  $topbarSubmenuHeaderBgColor: #3c4962;

  $topbarItemSubmenuBgColor: #3c4962;
  $topbarItemSubmenuTextColor: #f8fafc;
  $topbarItemSubmenuSecondaryTextColor: rgba(#f8fafc, 0.7);
  $topbarItemSubmenuBoxShadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);

  @import '../topbar_theme';
}
