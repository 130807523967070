.layout-menu-dark {
  $menuBgColor: #434b54;
  $menuShadow: 0 2px 12px 0 rgba(0, 0, 0, 0.06);

  $rootMenuTextColor: #cdd2db;

  $rootMenuItemTextColor: #e5e8ec;
  $rootMenuItemHoverBgColor: #4e565f;
  $activeRootMenuItemTextColor: #ffffff;
  $menuItemIconColor: #e5e8ec;

  $submenuItemTextColor: #e5e8ec;
  $submenuItemHoverBgColor: #676e77;
  $activeSubmenuItemTextColor: #ffffff;

  $externalSubmenuBgColor: #434b54;

  @import '../menu_theme';
}
