$bodyBgColor: linear-gradient(99deg, #f6f9fe 0%, #e8f1f8 100%) !default;
$textColor: #515c66 !default;
$textSecondaryColor: #657380 !default;

$contentBgColor: #fdfeff !default;
$contentAltBgColor: #e8f1f8 !default;
$dividerColor: #e8f1f8 !default;

$menuTooltipBgColor: #1c202c !default;
$menuTooltipTextColor: #ffffff !default;

:root {
  --content-alt-bg-color: #{$contentAltBgColor};
}

@import 'layout_common';
