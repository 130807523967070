/* You can add global styles to this file, and also import other style files */
$gutter: 1rem; //for primeflex grid system

@import '../node_modules/primeng/resources/primeng.min.css';
@import '../node_modules/primeflex/primeflex';
@import '../node_modules/primeicons/primeicons.css';
@import '../node_modules/prismjs/themes/prism-coy.css';
@import './presentations/assets/theme/denim/theme-light.scss';
@import './presentations/assets/layout/css/layout-light.scss';

.p-column-filter-overlay {
  min-width: unset;
}

// fixe le header des tables quand on scroll
.p-datatable-thead > tr:first-child {
  position: sticky;
  top: 0;
  z-index: 1;
}

.p-datatable .p-datatable-thead > tr > th {
  white-space: nowrap;

  & div {
    display: flex;
  }
}

.isDisabled {
  color: currentColor;
  cursor: not-allowed;
  opacity: 0.5;
  text-decoration: none;
}

table {
  .little-column {
    max-width: 150px;
  }

  .little-double {
    max-width: 65px;
  }

  .truncate {
    max-width: 150px !important;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.layout-menu-container {
  background: #3c4962;
  border-radius: 0;
}

.pull-right {
  align-items: flex-end;
  margin-left: auto;
}

.map-location {
  width: 100%;
  height: 590px;
}

.mat-dialog-container {
  color: #515c66;
}

.pointer {
  cursor: pointer;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

