@import 'topbar_common';

.layout-topbar {
  background-color: $topbarBgColor;
  box-shadow: 0 24px 64px -2px rgba(0, 0, 0, 0.02),
  0 6px 16px -2px rgba(0, 0, 0, 0.06),
  0 2px 6px -2px rgba(0, 0, 0, 0.08);

  .layout-topbar-wrapper {
    .layout-topbar-right {
      .menu-button {
        color: $topbarItemTextColor;
        background-color: $topbarItemBgColor;

        &:hover {
          background-color: $topbarItemTextHoverBgColor;
          @include transition(background-color $transitionDuration);
        }
      }

      .layout-topbar-actions {
        > li {
          > a {
            > .topbar-icon {
              color: $topbarItemTextColor;
            }

            &:hover {
              background-color: $topbarItemTextHoverBgColor;
              @include transition(background-color $transitionDuration);
            }
          }

          &.search-item {
            .search-input-wrapper {
              input {
                background-color: $topbarItemBgColor;
                color: $topbarItemTextColor;

                &::placeholder {
                  color: rgba($topbarItemTextColor, 0.7);
                }
              }

              i {
                color: $topbarItemTextColor;
              }
            }
          }

          &.user-profile {
            > a {
              color: $topbarItemTextColor;

              > span {
                color: $topbarItemSecondaryTextColor;
              }

              &:hover {
                background-color: transparent;
              }
            }

            > ul {
              hr {
                border-color: $topbarBorderColor;
              }

              .layout-submenu-header {
                background-image: linear-gradient(180deg, #2873d3 10%, #2c84d8 100%);
                background-image: linear-gradient(180deg, var(--primary-dark-color, #2873d3) 10%, var(--primary-color, #2c84d8) 100%);

                h6 {
                  color: #ffffff;
                  color: var(--primary-color-text, #ffffff);
                }

                span {
                  color: #ffffff;
                  color: var(--primary-color-text, #ffffff);
                }
              }

              > li {
                a {
                  i {
                    color: $topbarItemSubmenuTextColor;
                  }
                }
              }
            }
          }

          > ul {
            box-shadow: $topbarItemSubmenuBoxShadow;
            background-color: $topbarItemSubmenuBgColor;

            .layout-submenu-header {
              background-color: $topbarSubmenuHeaderBgColor;

              h6 {
                color: $topbarItemTextColor;
              }

              &:hover {
                background-color: $topbarSubmenuHeaderBgColor;
              }
            }

            > li {
              > a {
                h6 {
                  color: $topbarItemSubmenuTextColor;
                }

                > div {
                  > span {
                    color: $topbarItemSubmenuSecondaryTextColor;
                  }
                }
              }

              .menu-text {
                p {
                  color: $topbarItemSubmenuTextColor;
                }

                span {
                  color: $topbarItemSubmenuSecondaryTextColor;
                }
              }

              &:hover {
                background-color: $topbarItemTextHoverBgColor;
                @include transition(background-color $transitionDuration);
              }
            }
          }
        }
      }

      .layout-rightpanel-button {
        color: $topbarItemTextColor;
        background-color: $topbarItemBgColor;

        &:hover {
          background-color: $topbarItemTextHoverBgColor;
          @include transition(background-color $transitionDuration);
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .layout-topbar {
    box-shadow: none;

    .layout-topbar-wrapper {
      .layout-topbar-left {
        border-bottom: 1px solid $topbarBorderColor;
      }

      .layout-topbar-right {
        border-bottom: 1px solid $topbarBorderColor;
      }
    }
  }
}
