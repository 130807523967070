$primaryLightColor: #4d9fdc;
$primaryColor: #1b74c5;
$primaryDarkColor: #135aa9;
$primaryDarkerColor: #0d438d;
$primaryTextColor: #f8fafc;

$highlightBg: $primaryColor;
$highlightTextColor: $primaryTextColor;

@import '../../sass/theme/_theme_light.scss';
